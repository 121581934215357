/*
*	Author: Travolgi
*	Theme: SyncLove
*	Version: 1.0.0
*/

:root {
	--white: #fff;
	--black: #202124;
	--primary: #B7115EFF;
	--secondary: #9655d7;
	--ff-primary: 'Raleway', sans-serif;
	--ff-secondary: 'Playfair Display', serif;
	--scrollBar: #303134;
}
::-webkit-scrollbar {
	width: .7rem;
}
::-webkit-scrollbar-track {
	background: transparent;
}
::-webkit-scrollbar-thumb {
	background: var(--scrollBar);
	border-radius: 1rem;
}
* {
	margin: 0;
	padding: 0;
	outline: none;
}
html,
body {
	position: relative;
	font-family: var(--ff-primary);
	font-display: swap;
	font-size: 1.15rem;
	line-height: 1.2;
	color: var(--black);
	scrollbar-width: thin;
	scrollbar-color:var(--scrollBar) transparent;
	scroll-behavior: smooth;
	overflow-x: hidden;
}
body {
	padding-top: 58px;
}
#preload {
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	color: var(--primary);
	padding-top: 8rem;
	background-color: var(--white);
	box-sizing: border-box;
	z-index: 10000;
}
section.home-section {
	padding: 4rem 0;
}
.title-box {
	margin-bottom: 3rem;
}
.pt-fix {
	padding-top: 4rem;
}
.endiv {
	width: 100%;
}
svg.bg-arrow {
	height: 5rem;
	margin: -1px 0;
}
.bg-primary-2 {
	background: var(--primary) !important;
}
.btn,
#toTop,
.profile img,
.accordion-item,
.accordion-button,
.carousel-control-next-icon,
.carousel-control-prev-icon,
.card,
.card-intro,
.modal-container,
input,
textarea,
.form-control,
iframe {
	border-radius: 1.5rem !important;
	box-shadow: 0 2px 12px -2px rgba(0, 0, 0, .4);
}
.form-group {
	margin-bottom: 1rem;
}
.form-group label {
	margin-bottom: .5rem;
}
textarea {
	width: 100%;
	border: none;
}
.profile img,
.accordion-item,
.accordion-item:not(:first-of-type),
.card-location,
.card-whatsapp {
	border: .3rem solid var(--primary);
}
.modal-container {
	border: .3rem solid var(--secondary);
}
#preload,
.title-box,
#goDown,
.endiv,
.card-location,
.card-whatsapp,
.card-location::before,
.card-whatsapp::before,
footer>div:last-child {
	text-align: center;
}
h1,
h2,
.card h3 {
	font-family: var(--ff-secondary);
}
h1 {
	font-size: 2rem;
	font-weight: 700;
	margin-bottom: 1.5rem;
	color: var(--secondary);
}
h2 {
	font-size: 1.45rem;
	margin-bottom: 1rem;
}
h3 {
	font-size: 1rem;
	text-transform: uppercase;
	letter-spacing: .15rem;
	padding-left: 1px;
}
a {
	position: relative;
	font-size: 1.05rem;
	color:var(--secondary);
	overflow: hidden;
}
a:hover, a:focus, a:active {
	text-decoration: none;
	color:var(--secondary);
}
a:after {
	content: "";
	position: absolute;
	right: 0;
	width: 0;
	bottom: -2px;
	height: 2px;
	background: var(--secondary);
	transition-property: width;
	transition-duration: .3s;
	transition-timing-function: ease-out;
}
a:hover:after,
a:focus:after,
a:active:after {
	left: 0;
	right: auto;
	width: 100%;
}
span {
	display: inline-block;
	margin: 1rem 0;
}
span.numb {
	font-size: 1.7rem;
	line-height: 0;
	padding: 0 1rem 0 .15rem;
	color: var(--primary);
	-webkit-text-fill-color: white;
	-webkit-text-stroke-width: 1px;
	-webkit-text-stroke-color: var(--primary);
}
strong,
b {
	font-weight: 600;
}
#benefit {
	display: flex;
   justify-content: space-around;
	list-style: none;
	padding: 0;
	margin: 0;
	margin-top: 1.5rem;
}
#benefit li {
	display: inline-block;
	padding-right: 1rem;
}
#benefit li:last-child {
	padding-right: 0;
}
#stickyImg {
	position: sticky;
	top: 10rem;
	margin-top: -16rem;
	margin-bottom: -8rem;
	max-width: 95%;
}

/* navbar */
nav {
	background: var(--primary);
	color: var(--white);
}
.navbar-collapse {
	width: auto;
}
.navbar-dark .navbar-nav .nav-link,
footer nav a.nav-link {
	font-family: var(--ff-primary);
	color:var(--white);
}
.navbar-dark .navbar-toggler {
	border: 0;
	padding: 0 .75rem;
}
.navbar-dark .navbar-toggler-icon {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
button[type="button"].navbar-toggler,
.navbar-toggler:focus {
	box-shadow: none;
}
#btn-nav{
	border: 0;
	border-radius: 1rem;
	padding: 5px 10px;
	background-color: var(--secondary);
	color: var(--white);
}
.nav-link{
  color: white;
}
/* button */
.btn {
	margin-top: 2rem;
	padding: .4rem 1rem;
	border-color: var(--secondary);
	font-family: var(--ff-primary);
	text-transform: uppercase;
	color:var(--white);
	background: var(--secondary);
	transition: all .5 ease;
}
.btn:hover,
.btn:active,
.btn:focus {
	color:var(--white);
	box-shadow: 0 0 0 .25rem rgba(255,146,0,.5);
}
.btn-check:focus + .btn, .btn:focus {
	box-shadow: 0 0 0 .25rem rgba(255,146,0,.5);
}
#goDown,
.erBounce {
	font-size: 1.8rem;
	margin: auto;
	margin-bottom: -7rem;
	animation: bounce ease 2s infinite;
}
#toTop {
	background: var(--secondary);
	color: var(--white);
	display: none;
	position: fixed;
	bottom: 1.2rem;
	right: .8rem;
	width: 2rem;
	height: 2rem;
}
#toTop i {
	position: absolute;
	left: 28%;
	top: 25%;
}
/* whatsapp btn chat */
a#wachat {
	position: fixed;
	background: #25d366 url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23fff' d='M3.516 3.516c4.686-4.686 12.284-4.686 16.97 0 4.686 4.686 4.686 12.283 0 16.97a12.004 12.004 0 01-13.754 2.299l-5.814.735a.392.392 0 01-.438-.44l.748-5.788A12.002 12.002 0 013.517 3.517zm3.61 17.043l.3.158a9.846 9.846 0 0011.534-1.758c3.843-3.843 3.843-10.074 0-13.918-3.843-3.843-10.075-3.843-13.918 0a9.846 9.846 0 00-1.747 11.554l.16.303-.51 3.942a.196.196 0 00.219.22l3.961-.501zm6.534-7.003l-.933 1.164a9.843 9.843 0 01-3.497-3.495l1.166-.933a.792.792 0 00.23-.94L9.561 6.96a.793.793 0 00-.924-.445 1291.6 1291.6 0 00-2.023.524.797.797 0 00-.588.88 11.754 11.754 0 0010.005 10.005.797.797 0 00.88-.587l.525-2.023a.793.793 0 00-.445-.923L14.6 13.327a.792.792 0 00-.94.23z'/%3E%3C/svg%3E") 50% no-repeat;
	background-size: 60%;
	height: 3rem;
	width: 3rem;
	border-radius:100%;
	bottom: 4.5rem;
	right: 1.2rem;
	z-index: 100;
}
a#wachat:hover, a#wachat:active {
	background: #008617 url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23fff' d='M3.516 3.516c4.686-4.686 12.284-4.686 16.97 0 4.686 4.686 4.686 12.283 0 16.97a12.004 12.004 0 01-13.754 2.299l-5.814.735a.392.392 0 01-.438-.44l.748-5.788A12.002 12.002 0 013.517 3.517zm3.61 17.043l.3.158a9.846 9.846 0 0011.534-1.758c3.843-3.843 3.843-10.074 0-13.918-3.843-3.843-10.075-3.843-13.918 0a9.846 9.846 0 00-1.747 11.554l.16.303-.51 3.942a.196.196 0 00.219.22l3.961-.501zm6.534-7.003l-.933 1.164a9.843 9.843 0 01-3.497-3.495l1.166-.933a.792.792 0 00.23-.94L9.561 6.96a.793.793 0 00-.924-.445 1291.6 1291.6 0 00-2.023.524.797.797 0 00-.588.88 11.754 11.754 0 0010.005 10.005.797.797 0 00.88-.587l.525-2.023a.793.793 0 00-.445-.923L14.6 13.327a.792.792 0 00-.94.23z'/%3E%3C/svg%3E") 50% no-repeat;
	background-size: 60%;
}
a#wachat .wamessage {
	visibility: hidden;
	width: 11rem;
	background-color: var(--white);
	font-size:.8rem;
	border-radius: 10rem;
	text-align: center;
	box-shadow: 0 0 4px 2px #b9b9b9;
	padding: .2rem 0;
	position: fixed;
	bottom: 5rem;
	right: 5rem;
	z-index: 10000;
	transform: rotateY(90deg);
	transform-origin: right;
	transition: .8s cubic-bezier(.06, .8, .1, 1);
}
a#wachat:hover .wamessage {
	visibility: visible;
	transform: rotateY(0);
}
.profile {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	flex-wrap: wrap;
}
.profile img {
	width: 100%;
	margin-bottom: 1rem;
}
.profile article,
.card {
	margin: 0 1rem 3rem 0;
	width: calc(100% / 4 - 1rem);
	height: auto;
}
.profile article:nth-child(4),
.profile article:last-child {
	margin-right: 0;
}
.step h2 {
	margin-bottom: 0;
}
.step p {
	padding-left: 2.5rem;
	margin-bottom: 2rem;
}

/* faq */
.accordion-button {
	padding: .6rem 1rem;
	font-size: 1.02rem;
}
.accordion-item {
	margin-bottom: 1rem;
}
.accordion-button:not(.collapsed) {
   color: var(--secondary);
   background-color: var(--white);
	box-shadow: none;
}
.accordion-button:not(.collapsed)::after {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='rgb(255,123,123)'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")
}
.accordion-button:focus {
	box-shadow: none;
}
.accordion-collapse {
	transition: all 1s ease-in-out 0s;
}

/* carousel */
.carousel-item h2 {
	font-size: 1.02rem;
}
.carousel-item img {
	max-height: 9rem;
	display: block;
	margin: 1.5rem auto;
}
.carousel-control-next, .carousel-control-prev {
	width: unset
}
.carousel-control-prev {
	left: -1.2rem;
}
.carousel-control-next {
	right: -1.2rem;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
	background-color:var(--primary);
	background-size: 65%;
	width: 1.2rem;
	height: 2rem;
}

/* card */
.card-container {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 1rem;
	place-items: start;
}
.card {
	width: 100%;
	color:var(--black);
	padding: .5rem;
}
.card-intro {
	background: var(--white);
	color: var(--black);
	padding: 1.5rem;
}
.card-intro div:last-child {
	text-align: right;
}
.card img {
	border-radius: 50%;
	margin-top: 1rem;
	margin-right: 1rem;
	width: 5rem;
	height: 5rem;
	float: left;
}
.card h3 {
	font-size: 1rem;
	font-weight: 600;
	text-transform: unset;
	margin-top: 3rem;
	float: left;
	letter-spacing: unset;
}
.card h2 {
	font-size: 1.3rem;
}
.rating-5::before,
.rating-4::before,
.rating-3::before,
.rating-2::before,
.rating-1::before {
	content: '\2605\2605\2605\2605\2605';
	color: orange;
}
.rating-4::before {
	content: '\2605\2605\2605\2605';
}
.rating-3::before {
	content: '\2605\2605\2605';
}
.rating-2::before {
	content: '\2605\2605';
}
.rating-1::before {
	content: '\2605';
}
#reviews .card-body::before{
	content: url('data:image/svg+xml,<svg width="65" fill="rgb(255,123,123)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 448"><path d="M456,54a1.93,1.93,0,0,1,2,2v48a1.93,1.93,0,0,1-2,2h-8a86.1,86.1,0,0,0-86,86v86H464a26,26,0,0,1,26,26V432a26,26,0,0,1-26,26H336a26,26,0,0,1-26-26V192A138,138,0,0,1,448,54h8M168,54a1.93,1.93,0,0,1,2,2v48a1.93,1.93,0,0,1-2,2h-8a86.1,86.1,0,0,0-86,86v86H176a26,26,0,0,1,26,26V432a26,26,0,0,1-26,26H48a26,26,0,0,1-26-26V192A138,138,0,0,1,160,54h8M456,32h-8A160,160,0,0,0,288,192V432a48,48,0,0,0,48,48H464a48,48,0,0,0,48-48V304a48,48,0,0,0-48-48H384V192a64.06,64.06,0,0,1,64-64h8a23.94,23.94,0,0,0,24-24V56a23.94,23.94,0,0,0-24-24ZM168,32h-8A160,160,0,0,0,0,192V432a48,48,0,0,0,48,48H176a48,48,0,0,0,48-48V304a48,48,0,0,0-48-48H96V192a64.06,64.06,0,0,1,64-64h8a23.94,23.94,0,0,0,24-24V56a23.94,23.94,0,0,0-24-24Z" transform="translate(0 -32)"></path></svg>');
	display: block;
	margin-bottom: 1.2rem;
}
.card-location::before,
.card-whatsapp::before {
	content: url('data:image/svg+xml,<svg width="45" fill="rgb(137, 0, 82)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M192 96c-52.935 0-96 43.065-96 96s43.065 96 96 96 96-43.065 96-96-43.065-96-96-96zm0 160c-35.29 0-64-28.71-64-64s28.71-64 64-64 64 28.71 64 64-28.71 64-64 64zm0-256C85.961 0 0 85.961 0 192c0 77.413 26.97 99.031 172.268 309.67 9.534 13.772 29.929 13.774 39.465 0C357.03 291.031 384 269.413 384 192 384 85.961 298.039 0 192 0zm0 473.931C52.705 272.488 32 256.494 32 192c0-42.738 16.643-82.917 46.863-113.137S149.262 32 192 32s82.917 16.643 113.137 46.863S352 149.262 352 192c0 64.49-20.692 80.47-160 281.931z"></path></svg>');
	margin-top:1rem;
}
.card-whatsapp::before {
	content: url('data:image/svg+xml,<svg width="60" fill="rgb(37, 211, 102)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"></path></svg>');
}
.card-location,
.card-whatsapp {
	width: 100%;
}
.card-location h2,
.card-whatsapp h2 {
	margin-bottom: 0;
}
.underno {
	text-decoration: none;
}

/* maps */
.modal {
	display: flex;
   opacity: 0;
   visibility: hidden;
   transition: all .5s ease;
   align-items: center;
   justify-content: center;
}
.modal.open {
	visibility: visible;
	opacity: 1;
	transition-delay: .5s;
}
.modal-container {
	overflow: hidden;
	background-color: var(--white);
	width: 22rem;
	min-height: 22rem;
	margin: 0 calc(var(--bs-gutter-x)/ 2);
}
.modal-container iframe{
	margin-bottom: -.25rem;
}
iframe {
	width: 100%;
	height: 15rem;
}

/* form */
input:not([type="checkbox"]),
textarea,
.form-control {
	padding: .5rem 1rem;
	margin-bottom: 1rem;
}
.form-control:focus,
.form-check-input:focus,
textarea:focus {
	border-color: var(--primary);
	box-shadow: 0 0 0 .25rem rgba(137,0,82,.5);
	transition: .3s ease-in-out;
}
.form-check-input:checked {
	background-color: var(--primary);
	border-color: var(--primary);
}

/* 404 page */
#er404 h1 {
	font-size: 6rem;
}
#er404 img {
	margin:0;
	height:6.5rem;
}

/* link page */
body.link {
	min-height: 100vh;
	padding: 2rem 1rem;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: nowrap;
	background-color: var(--primary);
	color: var(--white);
	text-align: center;
}
body.link a {
	min-height: 1.2rem;
	text-decoration: none;
}
body.link .social {
	margin-top: 3rem;
}
body.link .social a {
	width: 2rem;
}
.btnBounce {
	animation: bounce ease 2s infinite;
}

/* footer */
footer {
	padding-block: 3rem 1rem;
	background: var(--primary);
	color: var(--white);
}
footer nav a.nav-link {
	padding: 0 0 .5rem 0;
}
footer nav a.nav-link:hover,
footer nav a.nav-link:focus {
	color: rgba(255,255,255,.75);
}
ul.social {
	padding: 0; margin-bottom: .5rem;
}
ul.social li {
	display: inline-block;
	padding-right: .5rem;
}

/* @media */
@media screen and (max-width: 825px) {
	#stickyImg {
		margin-top:-7rem;
		margin-bottom:3rem;
		max-width: 100%;
		position: unset;
	}
	svg.bg-arrow {
		height: 3rem;
	}
	.card-container {
		grid-template-columns: 1fr;
	}
	#goDown {
		display:none;
	}
	#benefit {
		flex-wrap: wrap;
	}
	.width-50 {
		width: 100%;
	}
}
@media screen and (max-width: 450px) {
	h1 {
		font-size: 1.7rem;
	}
	h2 {
		font-size: 1.35rem;
	}
	h3 {
		font-size: .9rem;
	}
	.title-box {
		text-align: left;
	}
	ul {
		padding-left: 1.2rem;
	}
	.mt-sm-4r {
		margin-top: 4rem;
	}
	.hero {
		max-width: 12rem;
		display: block;
		margin: 2rem auto -3rem auto;
	}
	.step {
		margin-bottom:2.5rem;
	}
	.profile article,
	.card {
		margin: 0 0 3.5rem 0;
		width: 100%;
	}
	.card h3 {
		float:none !important;
	}
	footer {
		padding: 2rem 0 4rem 0;
		text-align: center;
	}
	footer div {
		margin-bottom: 1.8rem;
	}
	footer div:last-child {
		margin-bottom: 0;
	}
}

/* animation */
a#wachat,
#toTop,
.carousel-item img {
	animation: fadeIn ease 2s;
}
@-webkit-keyframes bounce {
	from,
	20%,
	53%,
	to {
		-webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
	40%,
	43% {
		-webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
		animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
		-webkit-transform: translate3d(0, -30px, 0) scaleY(1.1);
		transform: translate3d(0, -30px, 0) scaleY(1.1);
	}
	70% {
		-webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
		animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
		-webkit-transform: translate3d(0, -15px, 0) scaleY(1.05);
		transform: translate3d(0, -15px, 0) scaleY(1.05);
	}
	80% {
		-webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		-webkit-transform: translate3d(0, 0, 0) scaleY(0.95);
		transform: translate3d(0, 0, 0) scaleY(0.95);
	}
	90% {
		-webkit-transform: translate3d(0, -4px, 0) scaleY(1.02);
		transform: translate3d(0, -4px, 0) scaleY(1.02);
	}
}
@keyframes bounce {
	from,
	20%,
	53%,
	to {
		-webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
	40%,
	43% {
		-webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
		animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
		-webkit-transform: translate3d(0, -30px, 0) scaleY(1.1);
		transform: translate3d(0, -30px, 0) scaleY(1.1);
	}
	70% {
		-webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
		animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
		-webkit-transform: translate3d(0, -15px, 0) scaleY(1.05);
		transform: translate3d(0, -15px, 0) scaleY(1.05);
	}
	80% {
		-webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		-webkit-transform: translate3d(0, 0, 0) scaleY(0.95);
		transform: translate3d(0, 0, 0) scaleY(0.95);
	}
	90% {
		-webkit-transform: translate3d(0, -4px, 0) scaleY(1.02);
		transform: translate3d(0, -4px, 0) scaleY(1.02);
	}
}
@-webkit-keyframes fadeIn {
	from { opacity: 0; }
	to { opacity: 1; }
}
@keyframes fadeIn {
	from { opacity: 0; }
	to { opacity: 1; }
}
