//$primaryColor: red !default;
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeflex/primeflex.css";


body {
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.spk-nav-bar {
  background-color: #B7115EFF;
}
.p-menuitem-text{
  color: white;
}
